import arbitrum from './assets/logos/arbitrum.svg';
import base from './assets/logos/base.svg';
import boredApeYachtClub from './assets/logos/boredApeYachtClub.svg';
import compound from './assets/logos/compound.svg';
import cryptoPunks from './assets/logos/cryptoPunks.svg';
import ethDenver from './assets/logos/ethDenver.svg';
import ethereum from './assets/logos/ethereum.svg';
import gnosis from './assets/logos/gnosis.svg';
import harmony from './assets/logos/harmony.svg';
import metamask from './assets/logos/metamask.svg';
import openSea from './assets/logos/openSea.svg';
import optimism from './assets/logos/optimism.svg';
import polygon from './assets/logos/polygon.svg';
import sporkDao from './assets/logos/sporkDao.svg';
import uniswap from './assets/logos/uniswap.svg';
import zkGame from './assets/logos/zkGame.svg';
import {UserTopic} from "./types";

export const TopicsList: UserTopic[] = [
  { name: 'arbitrum', logo: arbitrum, alias: 'Ar' },
  { name: 'base', logo: base, alias: 'B' },
  { name: 'boredApeYachtClub', logo: boredApeYachtClub, alias: 'Ba' },
  { name: 'compound', logo: compound, alias: 'Co' },
  { name: 'cryptopunks', logo: cryptoPunks, alias: 'Cp' },
  { name: 'ethDenver', logo: ethDenver, alias: 'Ed' },
  { name: 'ethereum', logo: ethereum, alias: 'Et' },
  { name: 'gnosisSafe', logo: gnosis, alias: 'Gs' },
  { name: 'harmony', logo: harmony, alias: 'H1' },
  { name: 'metamask', logo: metamask, alias: 'Mm' },
  { name: 'opensea', logo: openSea, alias: 'Os' },
  { name: 'optimism', logo: optimism, alias: 'Op' },
  { name: 'polygon', logo: polygon, alias: 'Pg' },
  { name: 'sporkDao', logo: sporkDao, alias: 'Sd' },
  { name: 'uniswap', logo: uniswap, alias: 'Us' },
  { name: 'zksync', logo: zkGame, alias: 'Zs' }
];
